<template>
    <div class="">
        <v-card :loading="loading" :disabled="loading" loader-height="2" class="all-width screen-height shadow-off">
            <v-card-text class="screen-height">
                <div class="d-flex ml-2 align-center justify-space-between">
                    <div class="d-flex">
                        <h1 class="d-flex align-center ma-0 text-h5 black--text font-weight-black">Threshold</h1>

                        <v-tooltip bottom content-class="tooltip-bottom" >
                            <template v-slot:activator="{ on, attrs }"> 
                                <v-btn 
                                    v-bind="attrs"
                                    v-on="on"
                                    class="shadow-off ml-1" icon @click="refreshData()"><v-icon
                                    size="24">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh</span>
                          </v-tooltip>
                    </div>
                    <div class="d-flex align-center justify-center">
                        <v-btn small height="4vh" color="black" class="mr-1 px-4 border-dark white--text"
                            v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')"
                            @click="locationdialog = true; locationdata = {}; locationdataerror = {};">
                            <v-icon class="mr-1">mdi-plus</v-icon>
                            <span>Add Threshold</span>
                        </v-btn>
                    </div>
                </div>

                <div class="d-flex align-center ml-2 justify-space-between my-3 ">

                    <lb-string placeholder="Search" label="" v-model="search" width="30vh" appendiconinner="mdi-magnify"
                        hidedetails :isClearable="false" radius="8px" />
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-tooltip left content-class="tooltip-left">
                              <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                              <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }"  class="ml-2 border-on"><v-icon color="caccounting">mdi-table-large-plus</v-icon></v-btn>
                              </template>
                              <span>Customize column</span>
                            </v-tooltip>
                          </template>

                        <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important"
                            class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                            <v-list-title class="ma-0  all-width">
                                <div
                                    class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                                    <p class="ma-0 font-weight-bold">Customize Column</p>
                                </div>
                                <v-divider></v-divider>
                                <lb-string label="" placeholder="Search" class="px-2 mt-3 mb-2" v-model="searchcolumn"
                                    width="30vh" :clearable="false" appendiconinner="mdi-magnify" hidedetails
                                    radius="8px" />
                            </v-list-title>


                            <v-list-item style="max-height:25vh ; overflow:scroll"
                                class="d-flex all-width align-start pa-0 ma-0 flex-column">
                                <v-list-item v-for="(item, index) in filteredHeaders" class="d-flex pa-0 px-2 ma-0 "
                                    :key="index">
                                    <v-checkbox color="caccounting" v-if="item.text !== 'Action'" :ripple="false"
                                        class="pa-0 ma-0" @change="addTableHeader(item)" hide-details
                                        :input-value="item.visible" :label="item.text"></v-checkbox>
                                </v-list-item>

                            </v-list-item>

                        </v-list>
                    </v-menu>
                </div>


                <lb-datatableaction :headers="headers" :tabledata="paginatedFilteredItems" :itemsperpage="itemsPerPage"
                    :searchterm="search" :bulkActions="false">
                    <template v-slot:tbody>
                        <tr v-for="item in paginatedFilteredItems" :key="item._id" class="cursor-pointer  "
                            :class="{ 'low-opacity': item.status === false }">
                            <template v-for="header in headers">
                                <td v-if="header.visible && header.value !== 'action'" :key="header.value"
                                    :class="{ 'description-header': header.value === 'name' }" class="px-2 single-line">
                                    <span> {{ getItemData(item, header.value) }}</span>
                                </td>
                                <td v-else-if="header.value === 'action'" :key="header.value"
                                    class=" px-3 single-line border-right border-left action-data cursor-default">

                                    <v-tooltip bottom content-class="tooltip-bottom" >
                                        <template v-slot:activator="{ on, attrs }"> 
                                            <v-btn v-bind="attrs" v-on="on" icon @click="editThreshold(item)"><v-icon>mdi-pencil-outline</v-icon></v-btn>
                                        </template>
                                        <span>Edit</span>
                                      </v-tooltip>
                                      
                                    <v-tooltip bottom content-class="tooltip-bottom" v-if="!item.status">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="cursor-default" v-bind="attrs" v-on="on" color="error">mdi-close-circle</v-icon>
                                        </template>
                                        <span> Disable</span>
                                      </v-tooltip>
                                      <v-tooltip bottom content-class="tooltip-bottom" v-else>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="cursor-default" v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                                        </template>
                                        <span>Enable</span>
                                    </v-tooltip>

                                </td>
                            </template>
                        </tr>
                    </template>
                </lb-datatableaction>
                <Pagination :list="filteredItemsfromsearch" :itemsperpage="itemsPerPage" @paginated-data="setPaginatedData" />

            </v-card-text>
        </v-card>

        <lb-drawer v-model="locationdialog" :heading="locationdata._id ? 'Edit Threshold' : 'Add Threshold'" width="600"
            :loading="locationdialogloading">
            <template v-slot:body>
                <div>
                    <v-row>
                        <v-col cols="12" class="my-0 py-1">
                            <p class="pa-0 ma-0  text-subtitle-1 mb-1">{{$store.state.LocationHeader || 'Location' }} Name*</p>
                            <lb-dropdown v-model="locationdata.name" :items="locationList" label=""
                                :error="locationdataerror.location" itemtext="name" itemvalue="value"
                                :readonly="locationdata._id ? true : false"
                                :clearable="locationdata._id ? false : true" />
                        </v-col>

                        <v-col cols="12" class="my-0 py-1">
                            <p class="pa-0 ma-0  text-subtitle-1 mb-1">Threshold*</p>
                            <lb-number v-model="locationdata.threshold" label="" min="0" placeholder="Threshold value"
                                :error="locationdataerror.threshold" />
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template v-slot:actions>
                <v-switch :label="enableSwitch === true ? 'Enable' : ' Disable'" class="mt-0" v-model="enableSwitch"
                    @onchange="toggleSwitch(locationdata)" inset hide-details></v-switch>
                <v-spacer></v-spacer>
                <v-btn small color="primary">
                    <span v-if="locationdata._id" @click="updateThreshold(locationdata)">Update</span>
                    <span v-else @click="addThreshold()">Add</span>
                </v-btn>
            </template>
        </lb-drawer>
    </div>
</template>

<script>
import Pagination from "../../../../views/common/Pagination.vue";
export default {
    data() {
        return {
            right: 'financialclose_master_threshold',
            locationdialog: false,
            locationdialogloading: false,
            locationdata: {},
            locationdataerror: {},
            loading: false,
            locationtypeItem: [],
            formatedData: [],
            locationListdata: [],
            locationList: [],
            locationtypedialog: false,
            headers: [
                {
                    value: "name",
                    text: "Name",
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    value: "threshold",
                    text: "Threshold",
                    visible: true,
                    filterable: false,
                    sortable: false,
                },
                {
                    value: "action",
                    text: "Action",
                    sortable: false,
                    datatype: "action",
                    lock: true,
                    visible: true,
                    alignment: "text-center",
                },
            ],
            listdata: [],
            enableSwitch: true,
            searchcolumn: "",
            searchTerm: "",
            search: "",
            itemsPerPage: 13,
            paginatedFilteredItems:[]
        };
    },
    created() {
        this.refreshData();
    },
    activated() {
        this.refreshData();
    },
    components: {
        Pagination
    },
    computed: {
        filteredHeaders() {
            const searchTerm = this.searchcolumn?.toLowerCase();
            return this.headers.filter(header =>
                header.value?.toLowerCase()?.includes(searchTerm)
            );
        },
        filteredItemsfromsearch() {
            let filteredData = this.formatedData.filter(item =>
                item.name?.toLowerCase().includes(this.search?.toLowerCase())
            );
            return filteredData;
        },
    },
    methods: {
        setPaginatedData(paginatedData) {
            this.paginatedFilteredItems = paginatedData; 
        },
        refreshData() {
            this.projectId = this.$route.params.id || "";
            this.getData();
            this.formateData();
        },
        formateData() {
            let ObjectTemp = {};
            let ArrayTemp = [];
            this.listdata.forEach((item, index) => {
                ObjectTemp = {
                    __key: index,
                    __formatedkey: index,
                    _id: item._id,
                    indexno: index + 1,
                    name: item.locationName,
                    locationId: item.locationId,
                    threshold: item.threshold,
                    created_at: item.created_at,
                    updated_at: item.updated_at,
                    status: item.status,
                };
                ArrayTemp.push(ObjectTemp);
            });
            this.formatedData = ArrayTemp;
        },
        addForm() {
            this.locationdialog = true;
            this.locationdata = { threshold: 0 };
            this.locationdataerror = {};
            this.locationUserList = [];
            this.customassertions = false;
        },
        async getData() {
            this.loading = true;
            await this.axios
                .post("/v2/financialclose/masters/threshold/get")
                .then(async (ele) => {
                    if (ele.data.status === "success") {
                        this.listdata = ele.data.data;
                        await this.axios
                            .post("/v2/financialclose/location/get")
                            .then((ele) => {
                                if (ele.data.status === "success") {
                                    this.locationListdata = ele.data.data;
                                    this.locationListdata.map(item => this.locationList.push(item.name))
                                    this.listdata = this.listdata.filter(obj1 => {
                                        var location = []
                                        location = this.locationListdata.filter(obj2 => {
                                            if (obj2._id === obj1.location) {
                                                return obj2;
                                            }
                                        });
                                        obj1.locationName = location[0].name
                                        return obj1
                                    })
                                    this.formateData()
                                }
                            })
                    }
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addThreshold() {
            this.locationdialogloading = true;
            let locationId = this.locationListdata.filter((item) => {
                if (item.name === this.locationdata.name) {
                    return item._id;
                }
            })
            this.locationdata.location = locationId[0]?._id || ""
            console.log(locationId)
            this.locationdata = { ...this.locationdata, 'status': this.enableSwitch }
            this.axios
                .post("/v2/financialclose/masters/threshold/add", { data: this.locationdata })
                .then((ele) => {
                    if (ele.data.status === "success") {
                        this.locationdialog = false;
                        this.$store.commit("sbSuccess", ele.data.message || "Added Successfully");
                        this.refreshData();
                    } else {
                        if (ele.data.data.length > 0) {
                            if (ele.data.data[0].index0)
                                this.locationdataerror = ele.data.data[0].index0;
                        } else throw new Error(ele.data.message);
                    }
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                })
                .finally(() => {
                    this.locationdialogloading = false;
                });
        },
        editThreshold(item) {
            this.locationdata = { ...item };
            this.locationdialog = true;
            this.enableSwitch = item.status
            this.locationdataerror = {};
            console.log(item);
        },
        updateThreshold(item) {
            this.locationdialogloading = true;
            item.status = this.enableSwitch
            this.axios
                .post("v2/financialclose/masters/threshold/edit/" + item._id, { data: item })
                .then((ele) => {
                    if (ele.data.status === "success") {
                        this.locationdialog = false;
                        this.$store.commit("sbSuccess", ele.data.mssage || 'Updated successfully');
                        this.refreshData();
                    } else {
                        if (ele.data.data.length > 0) {
                            if (ele.data.data[0].index0)
                                this.locationdataerror = ele.data.data[0].index0;
                        } else throw new Error(ele.data.message);
                    }
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                })
                .finally(() => {
                    this.locationdialogloading = false;
                });
        },
        toggleSwitch(item) {
            item.status = this.enableSwitch
        },
        getItemData(item, key) {
            return item[key];
        },
        addTableHeader(item) {
            this.headers.find((x) => x === item ? x.visible = !x.visible : "")
        },
    },
};
</script>

<style></style>